import { Box, Button, Grid, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AddCircleOutline as AddIcon, RemoveCircleOutline as RemoveIcon, DragIndicator as DragIcon } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Draggable, Container as DraggableContainer } from "react-smooth-dnd";

import Context from "./context";
import Field from "./Field";
import Operator from "./Operator";
import Value from "./Value";

const removeIconStyles = (t) => ({
    removeButton: {
        marginRight: t.spacing(-0.5),
    },
    removeIcon: {
        fill: "rgba(255, 0, 0, 0.9)",
    },
    dragIcon: {
        fill: "rgba(197, 197, 197, 1)",
    }
});

const useRuleStyles = makeStyles((t) => {
    return {
        ...removeIconStyles(t),
        container: {
            "& > div": {
                marginBottom: t.spacing(0.5),
                marginTop: t.spacing(0.5),
            },
            "cursor": "move",
        },
        valueGridItem: {
            flex: "auto",
        },
        cursorDefault: {
            cursor: "default"
        }
    };
});

const Rule = (props) => {
    const classes = useRuleStyles();
    const context = React.useContext(Context);

    const { id, level, position, rule } = props;
    const { combinator, field, operator, rules, value } = rule;

    const { dispatch, classes: ctxClasses, icons: ctxIcons } = context;

    const testId = `${level}-${position}`;

    return combinator ? (
        <RuleGroup combinator={combinator} id={id} level={level + 1} rules={rules} />
    ) : (
        <Grid
            container
            data-testid={`rule-${testId}`}
            spacing={2}
            className={classnames(classes.container, ctxClasses?.rule)}
        >
            <Grid item>
                <DragIcon className={classes.dragIcon} />
            </Grid>
            <Grid item>
                <IconButton
                    className={classnames(classes.removeButton, ctxClasses?.removeBtn)}
                    data-testid={`rule-${testId}-remove`}
                    size="small"
                    onClick={() => {
                        dispatch({ type: "remove-node", id });
                    }}
                >
                    {ctxIcons?.removeIcon ? ctxIcons?.removeIcon : <RemoveIcon className={classes.removeIcon} />}
                </IconButton>
            </Grid>
            <Grid item>
                <Field field={field} id={id} testId={testId} />
            </Grid>
            <Grid item>
                <Grid
                    item
                    onMouseDown={(event) => event.stopPropagation()}
                    className={classes.cursorDefault}
                >
                    <Operator field={field} id={id} operator={operator} testId={testId} />
                </Grid>
            </Grid>
            <Grid item className={classes.valueGridItem}>
                <Grid
                    item
                    onMouseDown={(event) => event.stopPropagation()}
                    className={classes.cursorDefault}
                >
                    <Value field={field} id={id} operator={operator} testId={testId} value={value} />
                </Grid>
            </Grid>
        </Grid>
    );
};

Rule.propTypes = {
    id: PropTypes.number.isRequired,
    level: PropTypes.number.isRequired,
    position: PropTypes.number.isRequired,
    rule: PropTypes.object.isRequired,
};

const useRuleGroupStyles = makeStyles((t) => ({
    actionButton: {
        "& svg": {
            marginRight: t.spacing(0.5),
            marginTop: t.spacing(0.25),
        },
        "textTransform": "none",
    },
    combinator: {
        height: 36,
        padding: t.spacing(0, 1.5),
    },
    group: {
        paddingLeft: (props) => (props.level === 0 ? t.spacing(1.5) : "none"),
        marginBottom: t.spacing(0.5),
        marginTop: (props) => (props.level > 0 ? t.spacing(0.5) : "none"),
    },
    cursorMove: {
        cursor: "move"
    },
    rowsGroup: {
        borderLeft: (props) => (props.level > 0 ? `2px solid ${t.palette.divider}` : "none"),
        paddingLeft: (props) => (props.level > 0 ? t.spacing(2) : "none"),
    },
    ...removeIconStyles(t),
}));

const RuleGroup = (props) => {
    const classes = useRuleGroupStyles(props);
    const context = React.useContext(Context);

    const { combinator, combinators, id, level, rules } = props;
    const testId = `group-${level}`;

    const { dispatch, maxLevels, classes: ctxClasses, icons: ctxIcons, translations: ctxTranslations } = context;

    return level <= maxLevels ? (
        <Grid
            container
            className={classnames(classes.group, ctxClasses?.group, (level !== 0 ? classes.cursorMove : null))}
            data-testid={testId}
            direction="column"
            spacing={1}
        >
            <Grid item>
                <Grid container spacing={2}>
                    {level !== 0 &&
                        <Grid item>
                            <DragIcon className={classes.dragIcon}/>
                        </Grid>
                    }
                    <Grid item>
                        <IconButton
                            className={classnames(classes.removeButton, ctxClasses?.removeBtn)}
                            data-testid={`${testId}-remove`}
                            disabled={level === 0}
                            size="small"
                            onClick={() => {
                                dispatch({ type: "remove-node", id });
                            }}
                        >
                            {ctxIcons?.removeIcon ? (
                                ctxIcons?.removeIcon
                            ) : (
                                <AddIcon className={level > 0 ? classes.removeIcon : null} />
                            )}
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <ToggleButtonGroup
                            exclusive
                            size="small"
                            value={combinator}
                            className={classnames(ctxClasses.conditionsBtnGroup)}
                            onChange={(event, value) => {
                                if (value) {
                                    dispatch({ type: "set-combinator", id, value });
                                }
                            }}
                        >
                            {combinators.map((item) => (
                                <ToggleButton
                                    key={item.value}
                                    data-testid={`${testId}-combinator-${item.value}`}
                                    className={classes.combinator}
                                    value={item.value}
                                >
                                    <Typography variant="body2">{item.label}</Typography>
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid item>
                        <Button
                            className={classnames(classes.actionButton, ctxClasses?.addRuleBtn)}
                            color="primary"
                            data-testid={`${testId}-add-rule`}
                            onClick={() => {
                                dispatch({ type: "add-rule", id });
                            }}
                        >
                            {ctxIcons?.addIcon ? ctxIcons?.addIcon : <AddIcon />}
                            {ctxTranslations?.ruleBtn ? ctxTranslations?.ruleBtn : "Rule"}
                        </Button>
                    </Grid>
                    {level < maxLevels && (
                        <Grid item>
                            <Button
                                className={classnames(classes.actionButton, ctxClasses?.addGroupBtn)}
                                color="primary"
                                data-testid={`${testId}-add-group`}
                                onClick={() => {
                                    dispatch({ type: "add-group", id });
                                }}
                            >
                                {ctxIcons?.addIcon ? ctxIcons?.addIcon : <AddIcon />}
                                {ctxTranslations?.groupBtn ? ctxTranslations?.groupBtn : "Group"}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {rules?.length > 0 && (
                <Grid item>
                    <Box className={classnames(classes.rowsGroup)}>
                        <DraggableContainer
                            onDrop={({ addedIndex, removedIndex }) => {
                                dispatch({ type: "move-rule", addedIndex, id, removedIndex });
                            }}
                        >
                            {rules.map((rule, position) => (
                                <Draggable key={rule.id}>
                                    <Rule id={rule.id} level={level} position={position} rule={rule} />
                                </Draggable>
                            ))}
                        </DraggableContainer>
                    </Box>
                </Grid>
            )}
        </Grid>
    ) : (
        <span />
    );
};

RuleGroup.defaultProps = {
    combinator: "and",
    combinators: [
        { label: "AND", value: "and" },
        { label: "OR", value: "or" },
    ],
    rules: [],
};

RuleGroup.propTypes = {
    combinator: PropTypes.string,
    combinators: PropTypes.array,
    id: PropTypes.number.isRequired,
    level: PropTypes.number.isRequired,
    rules: PropTypes.array,
};

export default RuleGroup;
