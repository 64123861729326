import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import classnames from "classnames";
import { dequal } from "dequal";
import PropTypes from "prop-types";
import React from "react";

import Context from "./context";

const Operator = React.memo(
    (props) => {
        const context = React.useContext(Context);
        const { field, id, operator, testId } = props;

        const {
            dispatch,
            filtersByValue,
            operatorsByType,
            operators: allOperators,
            operatorsByValue,
            getFilterType,
            strictlyTypedOperators,
            classes: ctxClasses,
            translations: ctxTranslations,
        } = context;

        let options;
        if (strictlyTypedOperators) {
            options = strictlyTypedOperators;
        } else {
            const type = getFilterType ? getFilterType(field) : filtersByValue[field].type;
            options = type ? operatorsByType[type] : allOperators;
        }

        const value = operator ? operatorsByValue[operator] : null;

        return (
            <Autocomplete
                className={classnames(ctxClasses?.operator)}
                fullWidth
                data-testid={`operator-${testId}`}
                disableClearable={true}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) => option.value === value.value}
                options={options}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={ctxTranslations?.operatorLabel ? ctxTranslations?.operatorLabel : "Operator"}
                        placeholder={
                            ctxTranslations?.operatorPlaceholder ? ctxTranslations?.operatorPlaceholder : "Placeholder"
                        }
                        size="small"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
                style={{ minWidth: 200 }}
                value={value}
                onChange={(event, selected) => {
                    const { value } = selected;
                    dispatch({ type: "set-operator", id, value });
                }}
            />
        );
    },
    (prevProps, nextProps) => {
        // Skip re-rendering if the operator didn't change.
        return dequal(prevProps, nextProps);
    }
);

Operator.propTypes = {
    field: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    id: PropTypes.number.isRequired,
    operator: PropTypes.string,
    testId: PropTypes.string.isRequired,
};

Operator.whyDidYouRender = false;

export default Operator;
