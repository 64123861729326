import { FormControlLabel, FormGroup, Radio, Switch, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import classnames from "classnames";
import { format } from "date-fns";
import { dequal } from "dequal";
import PropTypes from "prop-types";
import React from "react";

import Context from "./context";
import DatePicker from "./DatePicker";

const useFormControlLabelStyles = makeStyles((t) => {
    return {
        label: {
            fontSize: t.typography.fontSize,
        },
    };
});

const readNumericValue = (value) => {
    return value !== null && value !== undefined ? value : "";
};

const supportedTypes = new Set(["date", "integer", "multiselect", "number", "radio", "select", "switch", "text"]);

const Value = React.memo(
    (props) => {
        const classes = {
            formControlLabel: useFormControlLabelStyles(),
        };
        const context = React.useContext(Context);

        const { field, id, operator, value } = props;
        const {
            customOperators,
            operatorsByValue,
            dispatch,
            filtersByValue,
            getFilterType,
            classes: ctxClasses,
            translations: ctxTranslations,
        } = context;

        if (/null/i.test(operator)) {
            return <span />;
        }
        const testId = `value-${props.testId}`;
        let filterType = field ? (getFilterType ? getFilterType(field) : filtersByValue[field].type) : null;

        if (!filterType && operator) {
            // strictly typed
            filterType = operatorsByValue[operator]?.types[0]; //take first
        }

        if (!supportedTypes.has(filterType)) {
            const customOperator = customOperators[filterType];
            filterType = customOperator?.type;
        }
        let filterOptions = filtersByValue[field] ? filtersByValue[field].options : operatorsByValue[operator]?.options;

        if (!filterOptions) {
            filterOptions = [];
        }

        switch (filterType) {
            case "date":
                return (
                    <DatePicker
                        label={ctxTranslations?.fieldDateLabel ? ctxTranslations?.fieldDateLabel : "Date"}
                        placeholder={
                            ctxTranslations?.fieldDatePlaceholder
                                ? ctxTranslations?.fieldDatePlaceholder
                                : "Placeholder"
                        }
                        size="small"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className={classnames(ctxClasses?.value)}
                        clearable
                        data-testid={testId}
                        value={value || null}
                        onChange={(date) => {
                            const value = date ? format(date, "yyyy-MM-dd") : null;
                            dispatch({ type: "set-value", id, value });
                        }}
                    />
                );
            case "integer":
                return (
                    <TextField
                        label={ctxTranslations?.fieldIntegerLabel ? ctxTranslations?.fieldIntegerLabel : "Number"}
                        placeholder={
                            ctxTranslations?.fieldIntegerPlaceholder
                                ? ctxTranslations?.fieldIntegerPlaceholder
                                : "Placeholder"
                        }
                        type="number"
                        size="small"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className={classnames(ctxClasses?.value)}
                        data-testid={testId}
                        value={readNumericValue(value)}
                        onChange={(event) => {
                            const inputValue = event.target.value;
                            const newValue = inputValue.length > 0 ? Number(inputValue) : null;
                            if (newValue !== value) {
                                dispatch({ type: "set-value", id, value: newValue });
                            }
                        }}
                        onKeyPress={(event) => {
                            if (/\D/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                    />
                );
            case "multiselect":
                return (
                    <Autocomplete
                        className={classnames(ctxClasses?.value)}
                        filterSelectedOptions
                        fullWidth
                        multiple
                        openOnFocus
                        data-testid={testId}
                        disableCloseOnSelect={true}
                        getOptionLabel={(option) => option.label}
                        getOptionSelected={(option, value) => option.value === value.value}
                        limitTags={-1}
                        options={filterOptions}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={
                                    ctxTranslations?.fieldMultiSelectLabel
                                        ? ctxTranslations?.fieldMultiSelectLabel
                                        : "Multiselect"
                                }
                                placeholder={
                                    ctxTranslations?.fieldMultiSelectPlaceholder
                                        ? ctxTranslations?.fieldMultiSelectPlaceholder
                                        : "Placeholder"
                                }
                                size="small"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                        size="small"
                        style={{ paddingTop: 4, width: "auto" }}
                        value={filterOptions.filter((op) => value?.includes(op.value))}
                        onChange={(event, selected) => {
                            const value = (selected || []).map((item) => item.value);
                            dispatch({ type: "set-value", id, value });
                        }}
                    />
                );
            case "number":
                return (
                    <TextField
                        label={ctxTranslations?.fieldNumberLabel ? ctxTranslations?.fieldNumberLabel : "Number"}
                        placeholder={
                            ctxTranslations?.fieldNumberPlaceholder
                                ? ctxTranslations?.fieldNumberPlaceholder
                                : "Placeholder"
                        }
                        type="number"
                        size="small"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className={classnames(ctxClasses?.value)}
                        data-testid={testId}
                        value={readNumericValue(value)}
                        onChange={(event) => {
                            const { value: val } = event.target;
                            const v = val.replace(/[^.\d]|^0+/g, "").replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2") || null;
                            if (v !== value) {
                                dispatch({ type: "set-value", id, value: v });
                            }
                        }}
                    />
                );
            case "radio":
                return (
                    <FormGroup row className={classnames(ctxClasses?.value)}>
                        <FormControlLabel
                            classes={classes.formControlLabel}
                            control={
                                <Radio
                                    checked={value === true}
                                    color="primary"
                                    data-testid={`${testId}-true`}
                                    name={testId}
                                    value={value}
                                    onChange={() => {
                                        dispatch({ type: "set-value", id, value: true });
                                    }}
                                />
                            }
                            label={ctxTranslations?.fieldRadioLabelTrue ? ctxTranslations?.fieldRadioLabelTrue : "True"}
                            value={value}
                        />
                        <FormControlLabel
                            classes={classes.formControlLabel}
                            control={
                                <Radio
                                    checked={value === false}
                                    color="primary"
                                    data-testid={`${testId}-false`}
                                    name={testId}
                                    value={value}
                                    onChange={() => {
                                        dispatch({ type: "set-value", id, value: false });
                                    }}
                                />
                            }
                            label={
                                ctxTranslations?.fieldRadioLabelFalse ? ctxTranslations?.fieldRadioLabelFalse : "False"
                            }
                            value={value}
                        />
                    </FormGroup>
                );
            case "select":
                return (
                    <Autocomplete
                        className={classnames(ctxClasses?.value)}
                        data-testid={testId}
                        getOptionLabel={(option) => option.label}
                        getOptionSelected={(option, value) => option.value === value.value}
                        options={filterOptions}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={ctxTranslations?.fieldSelectLabel ? ctxTranslations?.fieldSelectLabel : "Select"}
                                placeholder={
                                    ctxTranslations?.fieldSelectPlaceholder
                                        ? ctxTranslations?.fieldSelectPlaceholder
                                        : "Placeholder"
                                }
                                size="small"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                        style={{ width: 250 }}
                        value={filterOptions.find((op) => value === op.value)}
                        onChange={(event, selected) => {
                            const value = selected ? selected.value : null;
                            dispatch({ type: "set-value", id, value });
                        }}
                    />
                );
            case "switch":
                return (
                    <Switch
                        className={classnames(ctxClasses?.value)}
                        color="primary"
                        data-testid={testId}
                        checked={value || false}
                        onChange={(event) => {
                            const value = event.target.checked;
                            dispatch({ type: "set-value", id, value });
                        }}
                    />
                );
            default:
                return (
                    <TextField
                        label={ctxTranslations?.fieldDefaultLabel ? ctxTranslations?.fieldDefaultLabel : "Value"}
                        placeholder={
                            ctxTranslations?.fieldDefaultPlaceholder
                                ? ctxTranslations?.fieldDefaultPlaceholder
                                : "Placeholder"
                        }
                        size="small"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className={classnames(ctxClasses?.value)}
                        fullWidth
                        data-testid={testId}
                        value={value || ""}
                        onChange={(event) => {
                            const { value } = event.target;
                            dispatch({ type: "set-value", id, value });
                        }}
                    />
                );
        }
    },
    (prevProps, nextProps) => {
        // Skip re-rendering if the value didn't change.
        return dequal(prevProps, nextProps);
    }
);

Value.propTypes = {
    field: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    id: PropTypes.number.isRequired,
    operator: PropTypes.string,
    testId: PropTypes.string.isRequired,
    value: PropTypes.any,
};

Value.whyDidYouRender = false;

export default Value;
