import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import classnames from "classnames";
import { dequal } from "dequal";
import PropTypes from "prop-types";
import React from "react";

import Context from "./context";

const Field = React.memo(
    (props) => {
        const context = React.useContext(Context);
        const { field, id, testId } = props;

        const {
            dispatch,
            filtersByValue,
            flattenedFilters,
            operatorsByType,
            operators: allOperators,
            renderFieldComponent,
            getFilterType,
            classes: ctxClasses,
            translations: ctxTranslations,
        } = context;
        const filter = field ? filtersByValue[field] : null;

        const onChange = (value) => {
            const type = getFilterType ? getFilterType(value) : filtersByValue[value].type;
            const operators = type == null ? allOperators : operatorsByType[type];
            const operator = operators?.length > 0 ? operators[0].value : null;
            dispatch({ type: "set-field", id, operator, value: value });
        };

        return (
            <>
                {renderFieldComponent ? (
                    renderFieldComponent(field, onChange)
                ) : (
                    <Grid item onMouseDown={(event) => event.stopPropagation()}>
                        <Autocomplete
                            className={classnames(ctxClasses?.field)}
                            fullWidth
                            data-testid={`field-${testId}`}
                            disableClearable={true}
                            groupBy={(option) => option.group}
                            getOptionLabel={(option) => option.label}
                            getOptionSelected={(option, value) => {
                                return option.value === value.value;
                            }}
                            options={flattenedFilters}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={ctxTranslations?.fieldLabel ? ctxTranslations?.fieldLabel : "Field"}
                                    placeholder={
                                        ctxTranslations?.fieldPlaceholder
                                            ? ctxTranslations?.fieldPlaceholder
                                            : "Placeholder"
                                    }
                                    size="small"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                            style={{ minWidth: 250 }}
                            value={filter}
                            onChange={(event, selected) => {
                                const value = selected ? selected.value : null;
                                onChange(value);
                            }}
                        />
                    </Grid>
                )}
            </>
        );
    },
    (prevProps, nextProps) => {
        // Skip re-rendering if the field didn't change.
        return dequal(prevProps, nextProps);
    }
);

Field.propTypes = {
    field: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    id: PropTypes.number.isRequired,
    testId: PropTypes.string.isRequired,
};

Field.whyDidYouRender = false;

export default Field;
